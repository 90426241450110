import { type IntlFormatters } from 'react-intl';
import type { DisplayColumn } from 'qonto/constants/table-view';
import type { LabelList } from 'qonto/react/graphql';

export const getColumnTitle = (
  columnId: DisplayColumn['id'],
  labelLists: LabelList[],
  formatMessage: IntlFormatters['formatMessage']
): string => {
  const getLabelName = (labelId: string): string => {
    const labels = [...labelLists].map(list => ({
      id: list.id.replaceAll('-', '_'),
      name: list.name,
    }));
    const label = labels.find(({ id }) => id === labelId);

    return label ? label.name : '';
  };

  const getLabelColumnName = (): string => {
    const name = getLabelName(columnId);
    if (!name) {
      return '';
    }
    return formatMessage(
      {
        id: 'transactions.modular_table.labels.header',
      },
      {
        label_category_name: name,
      }
    );
  };

  switch (columnId) {
    case 'transaction':
      return formatMessage({ id: 'transactions.modular_table.transactions.header' });
    case 'amount':
      return formatMessage({ id: 'transactions.modular_table.amount.header' });
    case 'operationDate':
      return formatMessage({ id: 'transactions.modular_table.operation_date.header' });
    case 'attachments':
      return formatMessage({ id: 'transactions.modular_table.attachments.header' });
    case 'reference':
      return formatMessage({ id: 'transactions.modular_table.reference.header' });
    case 'comments':
      return formatMessage({ id: 'transactions.modular_table.comments.header' });
    case 'paymentMethod':
      return formatMessage({ id: 'transactions.modular_table.payment_method.header' });
    case 'bkVerificationStatus':
      return formatMessage({ id: 'transactions.modular_table.verification.header' });
    case 'membership':
      return formatMessage({ id: 'transactions.modular_table.member.header' });
    case 'settledBalance':
      return formatMessage({ id: 'transactions.modular_table.settled_balance.header' });
    case 'status':
      return formatMessage({ id: 'transactions.modular_table.status.header' });
    case 'target':
      return formatMessage({ id: 'transactions.modular_table.credited_to.header' });
    case 'source':
      return formatMessage({ id: 'transactions.modular_table.debited_from.header' });
    case 'vat':
      return formatMessage({ id: 'transactions.modular_table.vat.header' });
    case 'category':
      return formatMessage({ id: 'transactions.modular_table.category.header' });
    default:
      return getLabelColumnName();
  }
};
